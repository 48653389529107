import { Box } from '@interstate/components/Box';
import { Button } from '@interstate/components/Button';
import {
  COLORS,
  LocalStorageKeys,
  STRING_CONSTANTS
} from 'constants/constants';
import React, { useEffect, useRef, useState } from 'react';
import './certification.css';
import { Interstitial } from '@interstate/components/Interstitial';
import { Typography } from '@interstate/components/Typography';
import { Alert } from '@interstate/components/Alert';
import { MFPayload } from 'models/MFPayload';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import ConfirmationModal from 'reusableComponents/confirmationModal/ConfirmationModal';
import {
  fetchCertificationData,
  postCertificationData
} from 'services/certification/certificationService';
import {
  CertData,
  QnaData,
  QuestionResponse
} from 'services/certification/certification.types';

declare global {
  interface Window {
    QuestionsAndAnnouncements?: any;
    NativeApp: {
      showAlertForMenuSwitch: (showAlert: boolean, message?: string) => void;
    };
  }
}
type CertificationProps = MFPayload & {
  onBack: () => void;
  onFinishComplete: () => void;
  locationCode?: string;
};

const Certification: React.FC<CertificationProps> = ({
  onBack,
  onFinishComplete,
  locationCode,
  ...props
}) => {
  const [loading, setLoading] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [isFinishDisabled, setIsFinishDisabled] = useState(true);
  const [qaAvailable, setQaAvailable] = useState(true);
  const [showButtons, setShowButtons] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const observerRef = useRef<MutationObserver | null>(null);
  const vehicleState = useSelector((state: RootState) => state.vehicle.data);
  const options =
    useSelector((state: RootState) => state.location.data?.locations) || [];

  const toUpperCase = (value: any): any => {
    return value ? value.toUpperCase() : undefined;
  };

  const [certData, setCertData] = useState<CertData>({
    consignmentId: vehicleState?.consignmentId || 'N/A',
    processStatus: '',
    certification: {
      startTime: new Date().toISOString(),
      endTime: '',
      locationCode: locationCode,
      workOrderNumber: vehicleState?.workOrderNumber || 'N/A',
      manheimAccountNumber: vehicleState?.manheimAccountNumber || 'N/A',
      vin: vehicleState?.vin || 'N/A',
      userId: toUpperCase(props.userDetails?.userId || 'N/A'),
      consignmentHref: vehicleState?.consignmentHref || 'N/A',
      unitHref: vehicleState?.unitHref || 'N/A',
      qnaData: {
        valid: false,
        prompting: false,
        questionResponses: [],
        announcements: [],
        totalQuestions: 0,
        questionnaireHref: '',
        metaData: []
      }
    }
  });

  const [initialResponses, setInitialResponses] = useState<QuestionResponse[]>(
    []
  );

  const consignmentId = certData.consignmentId;

  const fetchInitialData = async () => {
    if (!consignmentId) {
      console.error('Consignment ID is not available.');
      return;
    }

    try {
      const data = await fetchCertificationData(consignmentId);

      const { certification, processStatus } = data;

      const parsedCertification =
        typeof certification === 'string'
          ? JSON.parse(certification)
          : certification;

      setCertData((prevCertData) => ({
        ...prevCertData,
        processStatus: processStatus,
        certification: {
          ...prevCertData.certification,
          qnaData: {
            ...prevCertData.certification.qnaData
          }
        }
      }));
      setInitialResponses(parsedCertification.qnaData.questionResponses || []);
      renderQuestions(parsedCertification.qnaData.questionResponses);
    } catch (error) {
      console.error('Error fetching initial data:', error);
      renderQuestions([]);
    }
  };

  const startMutationObserver = () => {
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === 'childList' &&
          mutation.target instanceof HTMLElement
        ) {
          if (mutation.target.childElementCount > 0) {
            setLoading(false);
            cleanupMutationObserver();
            break;
          }
        }
      }
    });

    const target = document.getElementById(
      'cert-assist-questions-and-announcements-root'
    );
    if (target) {
      observer.observe(target, { childList: true, subtree: true });
      observerRef.current = observer;
    }
  };

  const cleanupMutationObserver = () => {
    if (observerRef.current) {
      observerRef.current.disconnect();
      observerRef.current = null;
    }
  };

  useEffect(() => {
    if (window.NativeApp) {
      window.NativeApp.showAlertForMenuSwitch(
        true,
        STRING_CONSTANTS.SAVE_INTERCEPT_WARNING_MESSAGE
      );
    }
    return () => {
      window.NativeApp.showAlertForMenuSwitch(false, '');
    };
  });

  // save
  useEffect(() => {
    const currentResponses = certData.certification.qnaData.questionResponses;
    const hasResponsesChanged =
      JSON.stringify(currentResponses) !== JSON.stringify(initialResponses);
    setHasChanges(hasResponsesChanged);

    // submit
    const totalQuestions = certData.certification.qnaData.totalQuestions;
    const allQuestionsAnswered = currentResponses.length === totalQuestions;

    if (certData.processStatus !== 'PARTIAL' && !hasResponsesChanged) {
      setIsFinishDisabled(true);
    } else {
      setIsFinishDisabled(!allQuestionsAnswered);
    }
    // eslint-disable-next-line
  }, [
    certData.certification.qnaData.questionResponses,
    certData.processStatus
  ]);

  const renderQuestions = (initialQuestionsResponse: QuestionResponse[]) => {
    if (!window.QuestionsAndAnnouncements) {
      console.error('QuestionsAndAnnouncements is not available.');
      setLoading(false);
      setQaAvailable(false);
      return;
    }

    const initialState = {
      announcements: [],
      questionResponses: initialQuestionsResponse
    };

    window.QuestionsAndAnnouncements.renderQuestions({
      onStateChange: (newState: any) => {
        if (loading) {
          setLoading(false);
          setShowButtons(true);
        }

        const questionResponses = newState.questionResponses.map(
          (response: any) => ({
            guid: response.guid,
            answers: response.answers,
            source: response.source,
            values: response.values,
            metadata: {
              updatedBy: toUpperCase(props.userDetails?.userId || 'N/A')
            }
          })
        );

        const qnaData: QnaData = {
          valid: newState.valid,
          prompting: newState.prompting,
          questionResponses,
          announcements: newState.announcements || [],
          totalQuestions: newState.totalQuestions,
          questionnaireHref: newState.questionnaireHref || '',
          metaData: newState.metaData || []
        };

        setCertData((prevCertData) => ({
          ...prevCertData,
          certification: {
            ...prevCertData.certification,
            qnaData
          }
        }));
        setShowAlert(false);
      },
      clientApplicationName: STRING_CONSTANTS.APP_NAME,
      rootElementId: 'cert-assist-questions-and-announcements-root',
      environment: props.appConfig?.qnaEnvironment,
      announcerSource: STRING_CONSTANTS.QE_ANNOUNCER_SOURCE,
      questionnaireHref: props.appConfig?.certFormUrl,
      initialState: initialState,
      colors: {
        activeButtonBackground: COLORS.LIGHT_SKY_BLUE,
        activeButtonBorder: COLORS.DEEP_BLUE,
        activeButtonText: COLORS.DEEP_BLUE,
        inactiveButtonBackground: COLORS.WHITE,
        inactiveButtonBorder: COLORS.DEEP_BLUE,
        inactiveButtonText: COLORS.DEEP_BLUE,
        progressIndicator: COLORS.WHITE
      }
    });
  };

  useEffect(() => {
    fetchInitialData();
    startMutationObserver();
    return cleanupMutationObserver;
    // eslint-disable-next-line
  }, []);

  const postUpdatedCertData = async (status: string) => {
    setLoading(true);
    const endTime = new Date().toISOString();

    const updatedCertification = {
      ...certData.certification,
      endTime: endTime
    };

    const updatedCertData = {
      ...certData,
      processStatus: status,
      certification: JSON.stringify(updatedCertification)
    };

    try {
      await postCertificationData(updatedCertData);
      setHasChanges(false);
      setShowAlert(false);
      return true;
    } catch (error) {
      console.error('Error posting updated certData:', error);
      setAlertMessage('Failed to store answers. Please try again.');
      setShowAlert(true);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const postAndNavigate = async (status: string) => {
    const isSaved = await postUpdatedCertData(status);
    if (isSaved) {
      if (locationCode) {
        // Find the location object based on locationCode
        const locationObj = options.find(
          (option) => option.locationCode === locationCode
        );

        if (locationObj) {
          // Manually create the label by combining locationName and locationCode
          const locationLabel = `${locationObj.locationName} - ${locationObj.locationCode}`;
          localStorage.setItem(LocalStorageKeys.LOCATION, locationLabel);
        }
      }
      onFinishComplete();
    }
  };

  const handleSave = () => {
    postAndNavigate('PARTIAL');
  };

  const handleFinish = () => {
    postAndNavigate('SUBMITTED');
  };

  const handleModalYesButtonAction = () => {
    onBack();
  };

  const handleModalNoButtonAction = () => {
    setShowModal(false);
  };

  const isFinishButtonDisabled = () => {
    return isFinishDisabled;
  };

  return (
    <Box>
      <Box id='cert-assist-questions-and-announcements-root'></Box>
      {loading && (
        <Interstitial
          data-testid='loading'
          fullScreen={true}
          size={2}
          message={showButtons ? 'Storing answers' : 'Loading Questions'}
        />
      )}
      {showAlert && (
        <Alert title='Error' type='error' className='alert-error'>
          <Typography variant='body-md'>{alertMessage}</Typography>
        </Alert>
      )}
      {!qaAvailable ? (
        <Typography
          variant={'h1'}
          data-testid='qe_not_available'
          className='error_card'
        >
          {STRING_CONSTANTS.QE_NOT_AVAILABLE}
        </Typography>
      ) : showButtons ? (
        <Box className='cert-buttons' component={'div'}>
          <Button
            onClick={handleSave}
            disabled={!hasChanges}
            className='cert-buttons-save'
          >
            {STRING_CONSTANTS.SAVE}
          </Button>
          <Button
            onClick={handleFinish}
            disabled={isFinishButtonDisabled()}
            className={
              isFinishButtonDisabled()
                ? 'cert-buttons-finish'
                : 'cert-buttons-finish cert-buttons-finish-color'
            }
          >
            {STRING_CONSTANTS.FINISH}
          </Button>
        </Box>
      ) : null}
      <ConfirmationModal
        modalHeader={STRING_CONSTANTS.EXIT_BEFORE_SAVING}
        modalBody={STRING_CONSTANTS.CERTIFICATION_EXIT_WARNING}
        confirmationText={STRING_CONSTANTS.YES}
        cancelText={STRING_CONSTANTS.NO}
        showModal={showModal}
        onContinue={handleModalYesButtonAction}
        onCancel={handleModalNoButtonAction}
      />
    </Box>
  );
};

export default Certification;
