export const STRING_CONSTANTS = {
  APP_NAME: 'Cert Assist UI',
  CERT_ASSIST: 'CERT ASSIST',
  SCAN: 'SCAN',
  START: 'START',
  CONTINUE: 'CONTINUE',
  WELCOME_VEHICLE_SPLASH: 'Welcome to Vehicle Splash Page',
  CHECKEDOUT: 'CHECKED_OUT',
  RECENTS: 'Recents',
  CANCEL: 'CANCEL',
  SAVE: 'SAVE',
  FINISH: 'FINISH',
  BACK: 'BACK',
  SELLER: 'SELLER',
  LOADING_CONFIG_FILES: 'Loading Config Files',
  QE_NOT_AVAILABLE: 'Question Engine Not Available',
  QE_ANNOUNCER_SOURCE: 'inspector',
  YES: 'YES',
  NO: 'NO',
  SAVE_INTERCEPT_WARNING_MESSAGE:
    'Your inspection progress since the last save will be lost.',

  //Error, warning strings
  VEHICLE_SOLD: 'VEHICLE IS SOLD',
  VEHICLE_SOLD_DESC:
    'This vehicle is sold or released. Do you want to continue?',
  INVALID_VEHICLE_ERROR_MESSAGE:
    'Invalid work order location combination. Please try again.',
  NO_RESPONSE_RECEIVED: 'No response received from the server',
  UNKNOWN_ERROR: 'An unknown error occurred',
  CERT_LANDING_TEXT: 'Certification Landing Page',
  DATA_NOT_AVAILABLE: 'Data Not Available',
  CERTIFICATION_EXIT_WARNING:
    'Are you sure you want to exit this certification without saving or submitting your answers?',
  EXIT_BEFORE_SAVING: 'Exit Before Saving?',

  // BFF URL
  HOST_PROXY_URL: '',
  APP_ID: 'cert-assist'
};

export enum LocalStorageKeys {
  LOCATION = 'location'
}

export const COLORS = {
  LIGHT_SKY_BLUE: '#EBF6FF',
  DEEP_BLUE: '#005BA8',
  WHITE: '#FFFFFF'
};
